import cc from "classnames"
import { useTranslation } from "react-i18next"
import { useStore } from "@nanostores/react"

import type { LayoutDef } from "@types"
import { $createAccountDialog } from "@stores/createAccountDialog"
import { analyticsTrackEvent } from "@utils/analytics"

import Link from "@components/Link"
import DialogWithImage from "@components/DialogWithImage"
import { BASE_CLASSNAMES, VERSIONS, SIZES } from "@components/Button"

interface CreateAccountDialogProps {
  layout: LayoutDef
}

const CreateAccountDialog = (props: CreateAccountDialogProps): JSX.Element => {
  const { is_open, skip_link, redirect_after_login } = useStore($createAccountDialog)
  const { t } = useTranslation<string>("common")

  const onCreateAccountClick = () => {
    analyticsTrackEvent("registration", { action: "begin_registration", label: "modal" })
  }

  return (
    <DialogWithImage
      id="create-account-dialog"
      is_open={is_open}
      onClose={() => $createAccountDialog.setKey("is_open", false)}
      image={props.layout.modal_create_account_image}
      can_close
    >
      <div className="mb-40" data-cy="create-account-dialog">
        <h1 className="text-24 leading-28 font-semibold mb-16">{props.layout.modal_create_account_headline}</h1>

        <div className="prose--sm" dangerouslySetInnerHTML={{ __html: props.layout.modal_create_account_copy }} />

        <Link
          href="/sign-up"
          query={redirect_after_login ? { redirect: redirect_after_login } : {}}
          class_name={cc(BASE_CLASSNAMES, VERSIONS.PRIMARY, SIZES.MEDIUM, "mr-8")}
          onClick={onCreateAccountClick}
        >
          {t("auth.createAccount")}
        </Link>
        {skip_link && (
          <Link href={skip_link} class_name={cc(BASE_CLASSNAMES, VERSIONS.SECONDARY, SIZES.MEDIUM, "mt-8")}>
            {t("auth.notNow")}
          </Link>
        )}
      </div>

      <p className="text-14 leading-16">
        {t("auth.alreadyRegistered")}{" "}
        <Link
          href="/sign-in"
          query={redirect_after_login ? { redirect: redirect_after_login } : {}}
          class_name="font-semibold underline"
        >
          {t("auth.alreadyRegisteredLink")}
        </Link>
      </p>
    </DialogWithImage>
  )
}

export default CreateAccountDialog
